import React from "react"
import { Helmet } from "react-helmet"

import { Title } from "./title"
import { Navbar } from "./navbar"
import { Footer } from "./footer"
import GoogleFont from "./GoogleFont"

export default ({ slug, title, description, children }) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <GoogleFont />
    </Helmet>
    <Navbar />
    <main id="content" role="main" className="px3">
      <Title title={title} />
      <section className="mb4">{children}</section>
    </main>
    <Footer />
  </div>
)
