import React from "react"
import Single from "../components/single"

export default ({ data }) => (
  <Single
    slug="contact"
    title="問い合わせ"
    description="Puzzle.dev への問い合わせ"
  >
    <h2 className="block mb4 pt2">お問い合わせ</h2>
    <form
      method="post"
      action="https://formspree.io/info@puzzle.dev"
      className="p0 m0 mb4"
    >
      <fieldset className="border-none p0 m0">
        <div className="ampstart-input inline-block relative m0 p0 mb3 ">
          <input
            type="text"
            name="name"
            id="name"
            className="block border-none p0 m0"
          />
          <label
            htmlFor="name"
            className="absolute top-0 right-0 bottom-0 left-0"
          >
            名前
          </label>
        </div>
        <div className="ampstart-input inline-block relative m0 p0 mb3 ">
          <input
            type="email"
            name="_replyto"
            id="_replyto"
            className="block border-none p0 m0"
          />
          <label
            htmlFor="_replyto"
            className="absolute top-0 right-0 bottom-0 left-0"
          >
            メールアドレス
          </label>
        </div>
        <div className="ampstart-input inline-block relative m0 p0 mb3 ">
          <textarea
            name="message"
            id="message"
            rows="5"
            className="block border-none p0 m0"
          />
          <label
            htmlFor="message"
            className="absolute top-0 right-0 bottom-0 left-0"
          >
            問い合わせ内容
          </label>
        </div>
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <button
          type="submit"
          className="ampstart-btn mb3 ampstart-btn-secondary"
        >
          送信
        </button>
      </fieldset>
    </form>
  </Single>
)
